import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";

const Eskuvo = ({ data }) => (
  <Layout>
    <div className="container content-wrap">
      <div className="row my-3">
        <div className="col">
          <h1>Esküvő</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-sm my-3">
          <GatsbyImage
            image={getImage(data.wedding1)}
            alt="Esküvő"
            className="rounded img-fluid"
          />
        </div>
        <div className="col-sm my-3">
          <GatsbyImage
            image={getImage(data.wedding2)}
            alt="Esküvő"
            className="rounded img-fluid"
          />
        </div>
        <div className="col-sm my-3">
          <GatsbyImage
            image={getImage(data.wedding3)}
            alt="Esküvő"
            className="rounded img-fluid"
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h2>Milyen egy esküvő a Szelencében?</h2>
          <ul>
            <li>
              Családias és otthonos – vintage hangulatú, barátságos kis terek,
              hogy ne vesszen el a lényeg a csillogó forgatagban.
            </li>
            <li>
              Egyedi – nem futószalagon pörgetjük az esküvőket és nem is
              sablonokban gondolkodunk. Ahány pár, annyiféle igény, hangulat,
              stílus.
            </li>
            <li>
              Meghitt – a kisebb létszámú (8-25 fő) esküvők mesterei vagyunk,
              imádjuk az aprónak tűnő, de fontos részleteket; célunk a
              bensőséges hangulat megteremtése.
            </li>
            <li>
              Rendhagyó – itt senki sem fog győzködni, hogy Újházi-tyúkhúslevest
              rendelj, nem kötelező elem a csokordobás, és nem kell öltönyben
              feszengenie egyik vendégednek sem.
            </li>
            <li>
              Rugalmas – egyeztetésben, ötletekben, menüsorban, dekorban... Azon
              vagyunk, hogy az igényeiteket maradéktalanul ki tudjuk szolgálni.
            </li>
            <li>
              Tudatos – választhatsz vegetáriánus vagy vegán ételsorainkból, de
              akár magad is behozhatod az ételeket, italokat.
            </li>
          </ul>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row">
        <div className="col">
          <h2>Ajánljuk neked a Szelencét, mint helyszínt, ha...</h2>
          <ul>
            <li>
              kisebb létszámú (8-50 fő), bensőséges esküvőben gondolkodsz (lehet
              akár csak egy pezsgős köszöntő vagy egy többfogásos vacsora is)
            </li>
            <li>kedveled az egyedi, személyre szabott megoldásokat</li>
            <li>fontos neked a minőség, az igényesség, a megbízhatóság</li>
            <li>
              szeretnéd, hogy az esküvőtök napja valóban rólatok szóljon és meg
              tudjátok élni minden pillanatát!
            </li>
          </ul>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col">
          <h2>Esküvői csomagok a Szelencében</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 my-3">
          <div className="card">
            <GatsbyImage
              image={getImage(data.wedding_package1)}
              alt="Esküvő"
              className="card-img-top img-fluid  mx-auto d-block"
            />
            <div className="card-body">
              <h4 className="card-title">„Pezsgős köszöntő” csomag</h4>
              <ul>
                <li>8-16 fő</li>
                <li>maxiumum 3 óra időtartam</li>
                <li>csak terembérlés</li>
                {/* <li>díja: 70.000 Ft.</li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-4 my-3">
          <div className="card">
            <GatsbyImage
              image={getImage(data.wedding_package2)}
              alt="Esküvő"
              className="card-img-top img-fluid  mx-auto d-block"
            />
            <div className="card-body">
              <h4 className="card-title">„Esküvői piknik” csomag</h4>
              <ul>
                <li>15-25 fő</li>
                <li>4-6 óra időtartam</li>
                <li>3 fő személyzet</li>
                <li>
                  italok szervírozása, poharak leszedése, mosogatása, elpakolása
                </li>
                <li>
                  hideg ételek szervírozása, leszedése; edények és eszközök
                  mosogatása, elpakolása
                </li>
                <li>étel és ital nincs benne az árban, behozható</li>
                {/* <li>
                  díja – létszám, személyzet, behozott étel függvényében:
                  110.000-200.000 Ft
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-4 my-3">
          <div className="card">
            <GatsbyImage
              image={getImage(data.wedding_package3)}
              alt="Esküvő"
              className="card-img-top img-fluid  mx-auto d-block"
            />
            <div className="card-body">
              <h4 className="card-title">„Rendhagyó lakodalom” csomag</h4>
              <ul>
                <li>15-25 fő</li>
                <li>4-6 óra időtartam</li>
                <li>4 fő személyzet</li>
                <li>
                  italok szervírozása, poharak leszedése, mosogatása, elpakolása
                </li>
                <li>
                  meleg ételek/többfogásos menüsor szervírozása, leszedése;
                  edények és eszközök mosogatása, elpakolása
                </li>
                <li>étel és ital nincs benne az árban, behozható</li>
                {/* <li>
                  díja – létszám, személyzet, behozott étel függvényében:
                  140.000-220.000 Ft
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row">
        <div className="col">
          <h2>Legfontosabb információk</h2>
          <p>
            Igyekeztünk a változó igényeknek megfelelően kialakítani esküvői
            csomagjainkat.
          </p>
          <p>A csomagok mindegyike tartalmazza az alábbiakat:</p>
          <ul>
            <li>teremhasználat az előre egyeztetett időpontban</li>
            <li>
              terem berendezése a megbeszéltek szerint (székek, asztalok,
              hosszabbító, stb.)
            </li>
            <li>tányérok, poharak, evőeszközök előkészítése, elpakolása</li>
            <li>takarítás a teremhasználat után</li>
            <li>előzetes egyeztetés lehetősége, személyes találkozó(k)</li>
            <li>menüsor kitalálása</li>
            <li>árajánlat elkészítése</li>
            <li>
              alap esküvői dekoráció vagy előző napi díszítés lehetősége (dátum
              függvényében).
            </li>
          </ul>
          <p>
            <strong>„Esküvői piknik”</strong> és{" "}
            <strong>„Rendhagyó lakodalom”</strong> csomag mellé ajándék
            tortakóstolás az előzetes személyes egyeztetés alkalmával.
          </p>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row">
        <div className="col">
          <h3>Menüsorok</h3>
          <p>
            Ezekből a menüsorokból választhatsz a „Rendhagyó lakodalom” csomag
            mellé:{" "}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 my-3">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Hipster menü </h4>
              {/* <h5 className="card-subtitle mb-2 text-muted">8.900 Ft/fő</h5> */}
              <ul className="card-text">
                <li>Kecskesajtos-narancsos céklasaláta</li>
                <li>Zöldborsó krémleves répacsipsszel</li>
                <li>Szelence burger sült édesburgonyával és coleslaw-val</li>
                {/* <li>
                  <strong>Csak leves vagy előétel + főétel: 7.500 Ft</strong>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-4 my-3">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Hagyományos menü </h4>
              {/* <h5 className="card-subtitle mb-2 text-muted">8.900 Ft/fő</h5> */}
              <ul className="card-text">
                <li>Hamis húsleves csigatésztával</li>
                <li>
                  Rántott tofu, sütőtök és cukkini zöldfűszeres-joghurtos
                  szósszal, hagymás-szezámmagos burgonyával
                </li>
                <li>Töltött káposzta</li>
                {/* <li>
                  <strong>Két fogás: 6.900 Ft</strong>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-4 my-3">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Jógi menü</h4>
              {/* <h5 className="card-subtitle mb-2 text-muted">8.900 Ft/fő</h5> */}
              <ul className="card-text">
                <li>
                  Fűszeres csicseriborsó saláta currys minipalacsinta ágyon
                </li>
                <li>Korianderes paradicsomleves zöldségcsipsszel</li>
                <li>
                  Zöldségpakora gránátalmás-kesus rizzsel, gyümölcsös
                  chutney-val
                </li>
                {/* <li>
                  <strong>Csak leves vagy előétel + főétel: 7.500 Ft</strong>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row my-3">
        <div className="col">
          <p>Rendelhetsz még tőlünk: </p>
          <ul>
            <li>esküvői torta (nyers vegán vagy hagyományos)</li>
            <li>sós és édes aprósüti</li>
            <li>házi készítésű keksz</li>
            <li>animátor szolgáltatás</li>
          </ul>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row">
        <div className="col">
          <h2>Partnereink</h2>
          <p>Jó szívvel ajánljuk partnereinket: </p>
          <div className="row text-center">
            <div className="col">
              <GatsbyImage
                image={getImage(data.wedding_partners4)}
                alt="Ulicsák Rita – esküvői dekor"
                className="rounded-circle img-fluid"
              />
              <h3>Ulicsák Rita – esküvői dekor</h3>
            </div>
            <div className="col">
              <GatsbyImage
                image={getImage(data.wedding_partners2)}
                alt="Brisign – kalligráfiát mindenhova – kalligráfia"
                className="rounded-circle img-fluid"
              />
              <h3>Brisign – kalligráfiát mindenhova – kalligráfia</h3>
            </div>
            <div className="col">
              <GatsbyImage
                image={getImage(data.wedding_partners3)}
                alt="Requiem Fleur – virág"
                className="rounded-circle img-fluid"
              />
              <h3>Requiem Fleur – virág</h3>
            </div>
          </div>
          <div className="row text-center">
            <div className="col">
              <GatsbyImage
                image={getImage(data.wedding_partners1)}
                alt="Beck Réka - Brékszer – köszönőajándék, kerámia<"
                className="rounded-circle img-fluid"
              />
              <h3>Beck Réka - Brékszer – köszönőajándék, kerámia</h3>
            </div>
            <div className="col">
              <GatsbyImage
                image={getImage(data.wedding_partners5)}
                alt="Krulix - fotó"
                className="rounded-circle img-fluid"
              />
              <h3>Krulix - fotó</h3>
            </div>
          </div>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row">
        <div className="col">
          <h3>Sikerült felcsigáznunk?</h3>
          <p>
            Egyeztessünk egy időpontot e-mailben és mesélj nekünk személyesen az
            elképzeléseidről!
          </p>
          <p>
            Várjuk megkeresésed az{" "}
            <a
              className="linkDark"
              href="mailto:info@szelencecafe.hu?Subject=Esküvő"
              target="_top"
            >
              info@szelencecafe.hu
            </a>{" "}
            e-mail címen!
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  query {
    wedding1: file(relativePath: { eq: "wedding/wedding1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          quality: 100
        )
      }
    }
    wedding2: file(relativePath: { eq: "wedding/wedding2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          quality: 100
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    wedding3: file(relativePath: { eq: "wedding/wedding3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          height: 400
          quality: 100
        )
      }
    }
    wedding_package1: file(
      relativePath: { eq: "wedding/wedding_package1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 350
          height: 250
          quality: 100
        )
      }
    }
    wedding_package2: file(
      relativePath: { eq: "wedding/wedding_package2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 350
          height: 250
          quality: 100
        )
      }
    }
    wedding_package3: file(
      relativePath: { eq: "wedding/wedding_package3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 350
          height: 250
          quality: 100
        )
      }
    }
    wedding_partners1: file(
      relativePath: { eq: "wedding/partners/brekszer.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 250
          height: 250
          quality: 100
          transformOptions: { grayscale: true }
        )
      }
    }
    wedding_partners2: file(
      relativePath: { eq: "wedding/partners/brisign.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 250
          height: 250
          quality: 100
          transformOptions: { grayscale: true }
        )
      }
    }
    wedding_partners3: file(
      relativePath: { eq: "wedding/partners/requiem.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 250
          height: 250
          quality: 100
          transformOptions: { grayscale: true }
        )
      }
    }
    wedding_partners4: file(
      relativePath: { eq: "wedding/partners/ulicsak.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 250
          height: 250
          quality: 100
          transformOptions: { grayscale: true }
        )
      }
    }
    wedding_partners5: file(
      relativePath: { eq: "wedding/partners/krulix.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 250
          height: 250
          quality: 100
          transformOptions: { grayscale: true }
        )
      }
    }
  }
`;

export const Head = () => <title>Szelence Café | Esküvő</title>;

export default Eskuvo;
